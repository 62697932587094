<template>
  <div>

    <portal to="breadcrumb-right">
      <b-button
        v-if="asset.asupSystemId"
        class="mr-1"
        size="sm"
        variant="secondary"
        :to="{ name: 'asset-to-asup-redirect', params: { id: asset.id }}"
      >
        Go to ASUP
      </b-button>
      <b-button
        v-if="$can('Core.Incidents.Create')"
        :to="{ name: 'asset-create-ticket', params: { id: asset.id }}"
        variant="primary"
        size="sm"
        class="mr-1"
      >
        <feather-icon icon="LifeBuoyIcon" />
        Create ticket
      </b-button>
    </portal>

    <b-overlay :show="isLoading">
      <b-row>
        <b-col
          md="4"
        >
          <b-card
            title="Asset information"
          >
            <b-card-text>
              <div
                v-if="asset.systemNumber"
              >
                <div class="font-weight-bold mt-1">
                  mentIQ Systemnumber
                </div>
                <div class="text-secondary">
                  {{ asset.systemNumber }}
                </div>
              </div>
              <div
                v-if="productDisplayName"
              >
                <div class="font-weight-bold mt-1">
                  Product
                </div>
                <div class="text-secondary">
                  {{ productDisplayName }}
                </div>
              </div>
              <div
                v-if="asset.hostname"
              >
                <div class="font-weight-bold mt-1">
                  Hostname
                </div>
                <div class="text-secondary">
                  {{ asset.hostname }}
                </div>
              </div>
              <div
                v-if="asset.serialnumber"
              >
                <div class="font-weight-bold mt-1">
                  Serialnumber
                </div>
                <div class="text-secondary">
                  {{ asset.serialnumber }}
                </div>
              </div>
              <div
                v-if="asset.dellServiceTag"
              >
                <div class="font-weight-bold mt-1">
                  Dell Service Tag
                </div>
                <div class="text-secondary">
                  {{ asset.dellServiceTag }}
                </div>
              </div>
              <div
                v-if="asset.hostId"
              >
                <div class="font-weight-bold mt-1">
                  Host Id
                </div>
                <div class="text-secondary">
                  {{ asset.hostId }}
                </div>
              </div>
              <div
                v-if="asset.baseEnabler"
              >
                <div class="font-weight-bold mt-1">
                  Base Enabler
                </div>
                <div class="text-secondary">
                  {{ asset.baseEnabler }}
                </div>
              </div>
              <div>
                <div class="font-weight-bold mt-1">
                  Bundle
                </div>
                <div class="text-secondary">
                  {{ asset | assetBundleDisplayName }}
                </div>
              </div>

              <div
                v-if="isCurrentUserHost"
              >
                <hr>

                <div class="font-weight-bold mt-1">
                  Medialine Business Unit
                </div>
                <div class="text-secondary">
                  {{ asset.medialineBusinessUnitId | medialineBusinessUnitName }}
                </div>

                <div class="font-weight-bold mt-1">
                  Tenant
                </div>
                <div class="text-secondary">
                  <span v-if="tenant.id">
                    {{ tenant.name }}
                  </span>
                  <span
                    v-else
                    class="bg-info text-dark"
                  >
                    No tenant assigned
                  </span>
                </div>
                <div v-if="asset.account">
                  <div class="font-weight-bold mt-1">
                    Account
                  </div>
                  <div class="text-secondary">
                    {{ asset.account.name }}<br>
                    {{ accountAddress }}
                  </div>
                </div>
                <div v-if="asset.account && asset.account.accountManager">
                  <div class="font-weight-bold mt-1">
                    Account manager
                  </div>
                  <div class="text-secondary">
                    {{ asset.account.accountManager.firstname }} {{ asset.account.accountManager.lastname }}
                  </div>
                </div>
              </div>

              <div v-if="asset.tenantId">
                <div class="font-weight-bold mt-1">
                  Organization unit
                </div>
                <div class="text-secondary">
                  <ou-lookup-input
                    v-model="asset.organizationUnit"
                    :tenant-id="asset.tenantId"
                    :asset-id="asset.id"
                  />
                </div>
              </div>

            </b-card-text>
          </b-card>

          <b-card>
            <template #header>
              <h4 class="card-title">
                Primary support contact
              </h4>
              <b-button-group
                v-if="isCurrentUserHost"
              >
                <b-button
                  v-if="asset.primarySupportContact"
                  size="sm"
                  variant="danger"
                  @click="unsetPrimaryContact"
                >
                  <feather-icon
                    icon="Trash2Icon"
                  />
                </b-button>
                <b-button
                  v-b-modal="'contact-select-modal'"
                  size="sm"
                  variant="primary"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </b-button>
                <ContactSelectModal
                  @selected="savePrimaryContact($event.id)"
                />
              </b-button-group>

            </template>
            <div>
              <div
                v-if="!primarySupportContact"
                class="text-secondary"
              >
                <p>No primary contact set</p>
              </div>
              <div v-else>
                <div v-if="primarySupportContact.lastname">
                  <div class="font-weight-bold mt-1">
                    Lastname
                  </div>
                  <div class="text-secondary">
                    {{ primarySupportContact.lastname }}
                  </div>
                </div>
                <div v-if="primarySupportContact.firstname">
                  <div class="font-weight-bold mt-1">
                    Firstname
                  </div>
                  <div class="text-secondary">
                    {{ primarySupportContact.firstname }}
                  </div>
                </div>
                <div v-if="primarySupportContact.email">
                  <div class="font-weight-bold mt-1">
                    Email
                  </div>
                  <div class="text-secondary">
                    {{ primarySupportContact.email }}
                  </div>
                </div>
                <div v-if="primarySupportContact.phone">
                  <div class="font-weight-bold mt-1">
                    Phone
                  </div>
                  <div class="text-secondary">
                    {{ primarySupportContact.phone }}
                  </div>
                </div>
                <div v-if="primarySupportContact.mobile">
                  <div class="font-weight-bold mt-1">
                    Mobile
                  </div>
                  <div class="text-secondary">
                    {{ primarySupportContact.mobile }}
                  </div>
                </div>
                <div v-if="primarySupportContact.jobTite">
                  <div class="font-weight-bold mt-1">
                    Job title
                  </div>
                  <div class="text-secondary">
                    {{ primarySupportContact.jobTite }}
                  </div>
                </div>
              </div>
            </div>
          </b-card>

          <b-card
            title="Technical details"
          >
            <b-card-text>
              <div
                v-if="asset.location"
              >
                <div class="font-weight-bold mt-1">
                  Location
                </div>
                <div class="text-secondary">
                  {{ asset.location }}
                </div>
              </div>
              <div
                v-if="asset.locationContact"
              >
                <div class="font-weight-bold mt-1">
                  Contact at location
                </div>
                <div class="text-secondary">
                  {{ asset.locationContact }}
                </div>
              </div>
              <div
                v-if="asset.version"
              >
                <div class="font-weight-bold mt-1">
                  Version
                </div>
                <div class="text-secondary">
                  {{ asset.version }}
                </div>
              </div>
              <div
                v-if="asset.ipAddress"
              >
                <div class="font-weight-bold mt-1">
                  IP Address
                </div>
                <div class="text-secondary">
                  {{ asset.ipAddress }}
                </div>
              </div>
              <div
                v-if="asset.licensedCapacityTb"
              >
                <div class="font-weight-bold mt-1">
                  Licensed Capacity
                </div>
                <div class="text-secondary">
                  {{ asset.licensedCapacityTb }} Tb
                </div>
              </div>
              <div
                v-if="asset.measuredCapacityTb"
              >
                <div class="font-weight-bold mt-1">
                  Measured Capacity
                </div>
                <div class="text-secondary">
                  {{ asset.measuredCapacityTb }} Tb
                </div>
              </div>
              <div
                v-if="asset.clients"
              >
                <div class="font-weight-bold mt-1">
                  Clients
                </div>
                <div class="text-secondary">
                  {{ asset.clients }}
                </div>
              </div>
            </b-card-text>
          </b-card>

        </b-col>
        <b-col md="8">

          <b-card>
            <template #header>
              <h4 class="card-title">
                Service &amp; SLA
              </h4>
              <div>
                <b-badge
                  v-if="hasRmsService"
                  variant="primary"
                  class="mr-1"
                >
                  RMS Service
                </b-badge>
                <b-badge
                  v-if="asset.hasProActiveService"
                  variant="primary"
                >
                  ProActive Service
                </b-badge>
              </div>
            </template>
            <b-card-text>
              <b-row>
                <b-col md="4">
                  <div class="font-weight-bold mt-1">
                    Service
                  </div>
                  <div class="text-secondary">
                    {{ asset.activeOn | formatDate }} - {{ asset.expiresOn | formatDate }}
                  </div>
                  <div>
                    <div class="font-weight-bold mt-1">
                      Vendor Service
                    </div>
                    <div class="text-secondary">
                      {{ asset.manufacturerActiveOn | formatDate }} - {{ asset.manufacturerExpiresOn | formatDate }}
                    </div>
                  </div>

                  <div
                    v-if="asset.supportPartner"
                  >
                    <div class="font-weight-bold mt-1">
                      {{ asset.supportPartner.name }}
                    </div>
                  </div>
                  <div
                    v-if="asset.supportPartnerNumber"
                  >
                    <div class="font-weight-bold mt-1">
                      Partner number
                    </div>
                    <div class="text-secondary">
                      {{ asset.supportPartnerNumber }}
                    </div>
                  </div>
                </b-col>
                <b-col md="8">
                  <app-collapse>
                    <app-collapse-item
                      v-if="isCurrentUserHost && asset.specialSla"
                      is-visible
                      title="Special SLA"
                      class="sla-collapse-item specialsla-collapse-item"
                    >
                      {{ asset.specialSla }}
                    </app-collapse-item>
                    <app-collapse-item
                      v-for="sla in assetSlas"
                      :key="sla.assetSlas"
                      :title="sla.name"
                      class="sla-collapse-item"
                    >
                      <div v-html="sla.description" />
                    </app-collapse-item>
                    <app-collapse-item
                      v-if="asset.supportPartner"
                      :title="asset.supportPartner.name"
                      class="sla-collapse-item"
                    >
                      {{ asset.supportPartner.description }}
                    </app-collapse-item>
                  </app-collapse>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card
            title="Configuration &amp; Components"
          >
            <p
              v-if="asset.configuration"
              class="configuration-text"
            >
              {{ asset.configuration }}
            </p>

            <table
              v-if="asset.components && asset.components.length > 0"
              class="table"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Serialnumber</th>
                  <th>Auth. Code</th>
                  <th>Enabler Code</th>
                  <th>LAC</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="component in asset.components"
                  :key="component.name"
                >
                  <td>{{ component.name }}</td>
                  <td>{{ component.serialnumber }}</td>
                  <td>{{ component.authCode }}</td>
                  <td>{{ component.enablerCode }}</td>
                  <td>{{ component.lac }}</td>
                </tr>
              </tbody>
            </table>
          </b-card>

          <b-card
            v-if="isCurrentUserHost"
          >
            <template #header>
              <h4 class="card-title">
                Internal note
              </h4>
              <b-button-group>
                <b-button
                  v-if="asset.internalNote !== internalNoteUpdate"
                  size="sm"
                  @click="resetInternalNote"
                >
                  Cancel
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="saveInternalNote"
                >
                  Save
                </b-button>
              </b-button-group>
            </template>
            <quill-editor
              v-model="internalNoteUpdate"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BCard, BCardText, BRow, BCol, BBadge, BButton, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import OuLookupInput from './OuLookupInput.vue'
import ContactSelectModal from '@/views/contacts/ContactSelectModal.vue'
import AssetService from '@/service/asset.service'
import ContactService from '@/service/contact.service'
import TenantService from '@/service/tenant.service'

import { SLA_RMS_ARTICLE_NUMBER } from '@/utils/consts'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BButton,
    BButtonGroup,
    BOverlay,
    quillEditor,
    ContactSelectModal,
    OuLookupInput,
  },
  data() {
    return {
      asset: {},
      assetSlas: [],
      internalNoteUpdate: null,
      primarySupportContact: null,
      isLoading: false,
      tenant: {},
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    productDisplayName() {
      if (this.asset.manufacturer && this.asset.productFamily && this.asset.model) {
        return `${this.asset.manufacturer} / ${this.asset.productFamily} / ${this.asset.model}`
      }

      return `${this.asset.manufacturer} / ${this.asset.productFamily}`
    },
    accountAddress() {
      if (!this.asset.account) {
        return null
      }

      let result = `${this.asset.account.street}, ${this.asset.account.zip} ${this.asset.account.city}`
      if (this.asset.account.countryCode !== 'DE') {
        result += ` ${this.asset.account.countryCode}`
      }

      return result
    },
    hasRmsService() {
      return this.assetSlas.some(x => x.articleNumber === SLA_RMS_ARTICLE_NUMBER)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.isLoading = true
      this.asset = await AssetService.getAsync(this.$route.params.id, { disableTenantFilter: true })
      this.internalNoteUpdate = this.asset.internalNote

      AssetService.getSlasOfAssetAsync(this.asset, { disableTenantFilter: true }).then(result => {
        this.assetSlas = result
      })

      if (this.asset.tenantId !== null) {
        TenantService.getAsync(this.asset.tenantId).then(result => {
          this.tenant = result
        })
      }

      if (this.asset.primarySupportContact) {
        ContactService.getAsync(this.asset.primarySupportContact.id, { disableTenantFilter: true }).then(result => {
          this.primarySupportContact = result
        })
      } else {
        this.primarySupportContact = null
      }

      this.isLoading = false
    },
    resetInternalNote() {
      // Reset internal note to original internal note of asset
      this.internalNoteUpdate = this.asset.internalNote
    },
    saveInternalNote() {
      this.updateAsset(this.internalNoteUpdate, this.asset.primarySupportContact?.id)
    },
    unsetPrimaryContact() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Do you really want to unset the primary contact?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, unset it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.savePrimaryContact(null)
        }
      })
    },
    savePrimaryContact(primaryContactId) {
      this.updateAsset(this.asset.internalNote, primaryContactId)
    },
    updateAsset(internalNote, primaryContactId) {
      this.isLoading = true
      AssetService.updateAsync(this.$route.params.id,
        {
          internalNotes: internalNote,
          primarySupportContactId: primaryContactId,
        },
        { disableTenantFilter: true })
        .then(() => {
          this.loadData()

          this.$toast.success('Updated!', {
            icon: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
  .sla-collapse-item >>> .collapse-title {
    font-size: 14px;
  }
  .sla-collapse-item >>> .card-body {
    white-space: pre-wrap;
  }

  .specialsla-collapse-item >>> .card-header {
    background-color: var(--primary);
    color: #fff;
  }

  .configuration-text {
    white-space: pre-wrap;
    overflow: auto;
    max-height: 400px;
  }
</style>
