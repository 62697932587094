<template>
  <b-input-group>
    <b-form-input
      :value="value"
      :placeholder="placeholder"
      disabled
    />
    <b-input-group-append>
      <b-button
        v-if="value && showDelete"
        class="lookup-btn-delete"
        variant="outline-danger"
        @click="$emit('delete-click')"
      >
        <feather-icon icon="DeleteIcon" />
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="$emit('search-click')"
      >
        <feather-icon icon="SearchIcon" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroupAppend, BInputGroup, BButton, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroupAppend, BInputGroup, BButton, BFormInput,
  },
  props: {
    placeholder: {
      type: String,
      default: '-',
    },
    value: {
      type: String,
      default: '',
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
    .lookup-btn-delete {
        padding-left: 10px;
        padding-right: 10px;
    }
</style>
