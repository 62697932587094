<template>
  <div>
    <b-modal
      id="ou-select-modal"
      v-model="modalShow"
      title="Select organization unit"
    >
      <v-jstree
        :data="ousTreeview"
        show-checkbox
        size="large"
        whole-row
        text-field-name="displayName"
        value-field-name="id"
        children-field-name="children"
        @item-click="itemClick"
      />

      <p
        v-if="ous.length == 0"
      >
        No organization units defined. Add organization unit to manage company structure.
      </p>

      <template #modal-footer="{ ok, cancel }">
        <b
          v-if="selectedOu"
        >Selected organization unit: {{ selectedOu.displayName }} </b><br>

        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          v-if="ous.length > 0"
          variant="primary"
          @click="useSelected()"
        >
          Use
        </b-button>
        <b-button
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import VJstree from 'vue-jstree'
import OuService from '@/service/ou.service'

export default {
  components: {
    BModal,
    BButton,
    VJstree,
  },
  props: {
    preSelectedId: {
      type: String,
      default: null,
    },
    tenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modalShow: false,
      ous: [],
      selectedOu: null,
    }
  },
  computed: {
    ousTreeview() {
      // Unflatten array
      // https://stackoverflow.com/a/55241491
      const nest = (items, id = null) => items
        .filter(item => item.parentId === id)
        .map(item => ({ ...item, children: nest(items, item.id), opened: true }))

      return nest(this.ous)
    },
  },
  methods: {
    show() {
      if (!this.tenantId) {
        this.$swal(
          'No tenant found.',
          'Please make sure the asset is assigned to a tenant.',
          'warning',
        )
        return
      }

      this.modalShow = true
      this.loadData()
    },
    loadData() {
      OuService.getAllListAsync({ impersonateAsTenant: this.tenantId }).then(result => {
        this.ous = result.data.items

        if (this.preSelectedId !== null) {
          for (let i = 0; i < this.ous.length; i += 1) {
            if (this.ous[i].id === this.preSelectedId) {
              this.ous[i].selected = true
              this.selectedOu = this.ous[i]
            }
          }
        }
      })
    },
    itemClick(node) {
      this.selectedOu = node.model
    },
    useSelected() {
      if (this.selectedOu === null) {
        this.$swal(
          'No organization unit selected.',
          'Please select a organization unit or cancel the dialog.',
          'warning',
        )
      } else {
        this.$emit('selected', {
          ou: this.selectedOu,
        })

        this.modalShow = false
      }
    },
  },
}
</script>
