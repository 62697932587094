<template>
  <div>
    <div v-if="$can('Core.Assets.ManageOrganizationUnits')">
      <input-lookup-button
        :value="ou.displayName || null"
        @search-click="$refs.ouSelectModal.show()"
        @delete-click="removeOu()"
      />

      <ou-select-modal
        ref="ouSelectModal"
        :tenant-id="tenantId"
        :pre-selected-id="ou.id || null"
        @selected="saveOu($event.ou)"
      />
    </div>
    <div v-else>
      {{ ou.displayName || '-' }}
    </div>
  </div>
</template>

<script>
import InputLookupButton from '@/components/input-lookup-button/InputLookupButton.vue'
import OuSelectModal from '@/views/shared/tenant/OuSelectModal.vue'
import AssetService from '@/service/asset.service'

export default {
  components: {
    InputLookupButton,
    OuSelectModal,
  },
  props: {
    tenantId: {
      type: String,
      default: null,
    },
    assetId: {
      type: String,
      required: true,
    },
    value: {
      type: Object, // { id, displayName }
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ou() {
      if (this.value === null) {
        return {}
      }

      return this.value
    },
  },
  methods: {
    removeOu() {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.updateOu(null)
        }
      })
    },
    saveOu(ou) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.updateOu(ou)
        }
      })
    },
    updateOu(ou) {
      this.isLoading = true

      return AssetService.updateOuAsync(this.assetId, (ou || {}).id || null, { disableTenantFilter: true })
        .then(() => {
          this.$emit('input', ou)

          this.$toast.success('Updated!', {
            icon: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
